import React from 'react'

// Components
import CustomLink from 'components/shared/CustomLink'
import Customer from 'components/shared/Customer'

// Third Party
import styled from 'styled-components'

const StyledCustomLink = styled(CustomLink)``

const StyledCaseInfo = styled.div``

const CaseInfo = ({ className, content }) => {
  return (
    <StyledCaseInfo className={`${className ? `${className}` : ``}`}>
      <div className="px-lg-5 pt-lg-0 pt-3">
        <div>
          <p className="color-secondary font-size-sm font-weight-xl mb-0">
            Klant
          </p>
          <p className="font-size-sm">{content.customer}</p>
        </div>
        <div>
          <p className="color-secondary font-size-sm font-weight-xl mb-0">
            Geïntegreerde oplossingen
          </p>
          <div className="d-flex">
            {content.solutions.map((solution, index) => (
              <StyledCustomLink
                className="mr-1"
                to={`/oplossingen/${solution.post_name}`}
              >
                <p className="font-size-sm">{`${solution.post_title}${
                  index + 1 !== content.solutions.length ? `,` : ``
                }`}</p>
              </StyledCustomLink>
            ))}
          </div>
        </div>
        <div>
          <p className="color-secondary font-size-sm font-weight-xl mb-0">
            ClearMind Regisseur(s)
          </p>
          <div className="py-5 mt-5">
            <Customer content={content.relation[0]} />
            {content.relation[1] ? (
              <Customer content={content.relation[1]} />
            ) : (
              <p />
            )}
            {content.relation[2] ? (
              <Customer content={content.relation[2]} />
            ) : (
              <p />
            )}
          </div>
        </div>
      </div>
    </StyledCaseInfo>
  )
}

export default CaseInfo
